import plugins from './plugins'

// 详细可查看各个组件目录下的index.js
const {
    Background, // 背景图
    FloatIcon,  // 悬浮Icon
    VideoComp, // 视频组件
    HotBanner, // 热区轮播
    NormalBanner, // 普通轮播
    AlertComp, // 弹窗组件
    NavComp, // 锚点组件
    LoopScroll, // 无限滚动
    Scroll, // 滑屏组件
    CountDownBG, // 倒计时背景
    CountDown, // 倒计时
    ArrowComp, // 箭头组件
    SlideComp, // 滑动组件
    Marquee, // 跑马灯
    ExpandHide, // 展开收起
    AccordionBanner, // 手风琴
    ShadeImage,
    Template,
    FlyImage,
    FlipImage,
    OpenDoorAlert,
    ScaleAlert,
    NestBanner,
    SingleNav,
    TransparentNav,
    ScaleBanner,
    HotBackground,
    VegaScroll,
    ScaleLoopScroll,
    BottomFlyImage,
    TopFlyImage,
    FlyImageHotArea,
    ChangeBtn,
    ShopInform,
    ScrollVideo,
    CubeBanner,
    HotAccordion,
    GifImg,
    ImgBanner,
    HotFadeBanner,
    SlideNavComp
} = plugins

const TestModule = {
    name: '测试组件',
    code: 'test-0',
    isLock: false,
    plugins: [
        SlideNavComp,
        GifImg,
        FlyImageHotArea,
        HotAccordion,
        TopFlyImage,
        BottomFlyImage,
        ScaleLoopScroll,
        VegaScroll,
        HotBackground,
        ScaleBanner,
        TransparentNav,
        SingleNav,
        NestBanner,
        ScaleAlert,
        OpenDoorAlert,
        FlipImage,
        FlyImage,
        Template,
        Background,
        FloatIcon,
        VideoComp,
        HotBanner,
        NormalBanner,
        AlertComp,
        NavComp,
        LoopScroll,
        Scroll,
        CountDownBG,
        CountDown,
        ArrowComp,
        SlideComp,
        Marquee,
        ExpandHide,
        AccordionBanner,
        ShopInform,
    ]
}

const VideoNavBannerModule = {
    name: '背景视频锚点轮播',
    code: 'videoshelf-0',
    isLock: false,
    plugins: [
        Background,
        VideoComp,
        HotBanner,
        NavComp,
    ]
}

const ViedoBannerModule = {
    name: '背景视频轮播',
    code: 'videoBanner-0',
    isLock: false,
    plugins: [
        Background,
        VideoComp,
        HotBanner,
    ]

}

const CountDownModule = {
    name: '倒计时',
    code: 'countdown-0',
    isLock: false,
    plugins: [
        CountDownBG,
        CountDown,
    ]
}

const AccordionBannerModule = {
    name: '手风琴',
    code: 'accordionbanner-0',
    isLock: false,
    plugins: [
        Background,
        AccordionBanner,
    ]
}

//  缺少动图
const SpecialAccordion = {
    name: '特殊手风琴',
    code: 'hotaccordion',
    isLock: false,
    plugins: [
        Background,
        HotAccordion,
        ImgBanner,
        GifImg,
    ]
}

const NavBannerModule = {
    name: '锚点切换',
    code: 'navchange-0',
    isLock: false,
    plugins: [
        Background,
        HotBanner,
        NavComp,
        ArrowComp,
    ]
}

const NavBannerFlyImageModule = {
    name: '锚点切换图片飞入',
    code: 'NavBannerFlyImageModule',
    isLock: false,
    plugins: [
        Background,
        HotBanner,
        NavComp,
        ArrowComp,
        FlyImage, 
    ]
}

const SliderBannerModule = {
    name: '左右滑动',
    code: 'llrrslide-0',
    isLock: false,
    plugins: [
        Background,
        SlideComp,
        ArrowComp,
    ]
}

const InfinityScrollModule = {
    name: '无限滚屏',
    code: 'infinityscroll-0',
    isLock: false,
    plugins: [
        Background,
        LoopScroll,
    ]
}

const ScrollModule = {
    name: '滑屏',
    code: 'normalscroll-0',
    isLock: false,
    plugins: [
        Background,
        Scroll,
    ]
}

const MarqueeModule = {
    name: '跑马灯',
    code: 'marquee-0',
    isLock: false,
    plugins: [
        Background,
        Marquee,
    ]
}

const ExpandHideModule = {
    name: '展开收起',
    code: 'expandhide-0',
    isLock: false,
    plugins: [
        ExpandHide,
    ]
}

const AlertBannerModule = {
    name: '弹窗轮播',
    code: 'kv-0', // 类别 + 编号
    isLock: false,
    plugins: [
        Background,
        NormalBanner,
        FloatIcon,
        AlertComp,
    ]
}

// 锚点飞入图
const MdBannerFlyTopBottom = {
    name: '锚点热区轮播飞入（上层+下层）',
    code: 'md-banner-fly-tb',
    isLock: false,
    plugins: [
        Background,
        NavComp,
        HotBanner,
        TopFlyImage,
        BottomFlyImage,
    ]
}

const MdBannerFlyBottom = {
    name: '锚点热区轮播飞入（下层）',
    code: 'md-banner-fly-b',
    isLock: false,
    plugins: [
        Background,
        NavComp,
        HotBanner,
        BottomFlyImage,
    ]
}

const MdBannerFlyTop = {
    name: '锚点热区轮播飞入（上层）',
    code: 'md-banner-fly-t',
    isLock: false,
    plugins: [
        Background,
        NavComp,
        HotBanner,
        TopFlyImage,
    ]
}

const MdNormalBannerFlyTopBottom = {
    name: '锚点切换视频轮播图片飞入',
    code: 'md-normal-banner-fly-tb',
    isLock: false,
    plugins: [
        Background,
        NormalBanner,
        TopFlyImage,
        BottomFlyImage,
        NavComp,
        HotBanner,
        GifImg
    ]
}
// 滑动锚点
const SlideNavCompModule={
    name: '滑屏锚点左右滑动',
    code: 'navchange-llrrslide',
    isLock: false,
    plugins: [
        Background,
        SlideComp,
        GifImg,
        SlideNavComp
    ]
}
const SlideNavCompTabModule={
    name: '滑屏锚点切换',
    code: 'navchange-llrrslidetab',
    isLock: false,
    plugins: [
        Background,
        HotBanner,
        SlideNavComp,
        ArrowComp
    ]
}
const BannerPrice={
    name: '热区轮播价格渐现',
    code: 'banner-Price-change',
    isLock: false,
    plugins: [
        Background,
        HotBanner,
    ]
}
// 普通飞入图


// 锚点



// ----



// 嵌套轮播
const BigBannerNestSmallOne = {
    name: '大轮播嵌套小轮播',
    code: 'big-banner-nest-small-one',
    isLock: false,
    plugins: [
        Background,
        NormalBanner,
        NestBanner,
        ArrowComp,
    ]
}
// 锚点 + 箭头

const BgVideoMdBannerArrow = {
    name: '背景视频锚点轮播+箭头',
    code: 'BgVideoMdBannerArrow',
    isLock: false,
    plugins: [
        Background,
        VideoComp,
        HotBanner,
        NavComp,
        ArrowComp,
    ]
}

const BgVideoNavBannerIcon = {
    name: '背景视频锚点轮播+ICON',
    code: 'BgVideoNavBannerIcon',
    isLock: false,
    plugins: [
        Background,
        VideoComp,
        HotBanner,
        NavComp,
        FloatIcon,
    ]
}

const ImageBannerArrow = {
    name: '图片轮播+箭头',
    code: 'ImageBannerArrow',
    isLock: false,
    plugins: [
        Background,
        HotBanner,
        ArrowComp,
    ]
}

const BgVideoBannerArrow = {
    name: '背景视频轮播+箭头',
    code: 'BgVideoBannerArrow',
    isLock: false,
    plugins: [
        Background,
        VideoComp,
        HotBanner,
        ArrowComp,
    ]
}

const MdVideoHotBanner = {
    name: '锚点视频热区',
    code: 'MdVideoHotBanner',
    isLock: false,
    plugins: [
        Background,
        NavComp,
        NormalBanner,
        HotBanner,
    ]
}

const MdVideoBannerFlyTopAndBottom = {
    name: '锚点视频切换轮播飞入',
    code: 'MdVideoBannerFlyTopAndBottom',
    isLock: false,
    plugins: [
        Background,
        NavComp,
        NormalBanner,
        TopFlyImage,
        BottomFlyImage,
    ]
}

// ----
// 普通飞入

const BgVideoBannerFlyImg = {
    name: '背景视频轮播图片飞入',
    code: 'BgVideoBannerFlyImg',
    isLock: false,
    plugins: [
        Background,
        VideoComp,
        HotBanner,
        FlyImage,
    ]
}

const NormalFlyImage = {
    name: '图片飞入',
    code: 'normal-fly-image',
    isLock: false,
    plugins: [
        Background,
        FlyImage,
    ]
}

const CustomHotFlyImage = {
    name: '热区图片飞入',
    code: 'custom-hot-fly-image',
    isLock: false,
    plugins: [
        Background,
        FlyImageHotArea,
    ]
}

const BgVideoMdBannerFlyImage = {
    name: '背景视频锚点轮播图片飞入',
    code: 'BgVideoMdBannerFlyImage',
    isLock: false,
    plugins: [
        Background,
        VideoComp,
        NavComp,
        FlyImage,
        HotBanner,
    ]
}

// 换一换

const FlipChangeModule = {
    name: '翻转换一换',
    code: 'FlipChangeModule',
    isLock: false,
    plugins: [
        Background,
        FlipImage,
        ChangeBtn
    ]
}


const ShadeChangeModule = {
    name: '渐变换一换',
    code: 'ShadeChangeModule',
    isLock: false,
    plugins: [
        Background,
        ShadeImage,
        ChangeBtn,
    ]
}
 
// ---


// 其他

const VideoScroll = {
    name: '视频背景滑屏',
    code: 'video-scroll-t',
    isLock: false,
    plugins: [
        Background,
        Scroll,
        VideoComp,
    ]
}

const VideoLeftAndRight = {
    name: '视频背景左右滑动',
    code: 'video-left-and-right',
    isLock: false,
    plugins: [
        Background,
        SlideComp,
        VideoComp,
    ]
}
const ScaleBannerModule = {
    name: '放大缩小轮播',
    code: 'scale-banner-module',
    isLock: false,
    plugins: [
        ScaleBanner,
        Background,
    ]
}

const VideoImageBanner = {
    name: '视频图片轮播',
    code: 'video-image-banner',
    isLock: false,
    plugins: [
        Background,
        NormalBanner,
    ]
}

const NormalImageBanner = {
    name: '图片轮播',
    code: 'norma-image-banner-md5',
    isLock: false,
    plugins: [
        Background,
        HotBanner,
    ]
}

const OpenDoorBannerModule = {
    name: '开门弹窗轮播',
    code: 'open-door-banner-module',
    isLock: false,
    plugins: [
        Background,
        NormalBanner,
        FloatIcon,
        OpenDoorAlert,
    ]
}

const ScaleLoopScrollModule = {
    name: '无限滚动放大缩小',
    code: 'ScaleLoopScrollModule',
    isLock: false,
    plugins: [
        Background,
        ScaleLoopScroll,
    ]
}

const VideoBgLoopScroll = {
    name: '视频背景无限滚屏',
    code: 'VideoBgLoopScroll',
    isLock: false,
    plugins: [
        Background,
        VideoComp,
        LoopScroll,
    ]
}

const VideoBannerLoopScroll = {
    name: '视频轮播无限滚屏',
    code: 'VideoBannerLoopScroll',
    isLock: false,
    plugins: [
        Background,
        NormalBanner,
        Scroll,
        LoopScroll,
    ]
}

const VideoBannerIcon = {
    name: '视频轮播+ICON',
    code: 'VideoBannerIcon-md',
    isLock: false,
    plugins: [
        Background,
        NormalBanner,
        FloatIcon,
    ]
}

const VideoBgLeftAndRightSlideDotIcon = {
    name: '视频背景左右滑动+ICON',
    code: 'VideoBgLeftAndRightSlideDotIcon',
    isLock: false,
    plugins: [
        Background,
        VideoComp,
        SlideComp,
        FloatIcon,
    ]
}

const ImageBannerIcon = {
    name: '图片轮播+ICON',
    code: 'ImageBannerIcon-md',
    isLock: false,
    plugins: [
        Background,
        HotBanner,
        FloatIcon,
    ]
}

const ShopInformModule = {
    name: '店铺通知',
    code: 'ShopInformModule',
    isLock: false,
    plugins: [
        Background,
        ShopInform,
    ]
}

const TransparentHotBanner = {
    name: '渐变切换自定义热区',
    code: 'TransparentHotBanner',
    isLock: false,
    plugins: [
        Background,
        HotFadeBanner,
        TransparentNav,
    ]
}

const CustomAlertBanner = {
    name: '缩放弹窗轮播',
    code: 'CustomAlertBanner',
    isLock: false,
    plugins: [
        Background,
        NormalBanner,
        ScaleAlert,
    ]
}

const ScrollVideoModule = {
    name: '视频滑屏',
    code: 'ScrollVideoModule',
    isLock: false,
    plugins: [
        Background,
        ScrollVideo,
    ]
}

const ScrollVideoIconModule = {
    name: '视频滑屏+ICON',
    code: 'ScrollVideoIconModule',
    isLock: false,
    plugins: [
        Background,
        ScrollVideo,
        FloatIcon,
    ]
}

const CubeBannerAlert = {
    name: '3D弹窗轮播',
    code: 'CubeBannerAlert',
    isLock: false,
    plugins: [
        Background,
        CubeBanner,
        FloatIcon,
        AlertComp,
    ]
}

const HotBgBanner = {
    name: '热区背景视频轮播',
    code: 'HotBgBanner',
    isLock: false,
    plugins: [
        HotBackground,
        NormalBanner,
    ]
}

// const Special


//缩放弹窗轮播	背景图+普通轮播+弹窗

export const _template = [
    '热区轮播价格渐显',
    // '滑动锚点切换',
    '滑动锚点',
    // '热区背景视频轮播',
    // '3D弹窗轮播',
    // '视频滑屏',
    // '视频滑屏+ICON',
    '锚点热区轮播飞入（上层+下层）',
    // '视频背景左右滑动',
    // '视频背景滑屏',
    // '锚点切换视频轮播图片飞入',v
    // '锚点热区轮播飞入（上层）',
    '锚点热区轮飞入（上层+下层）',
    // '背景视频锚点轮播+箭头',
    // '背景视频轮播图片飞入',
    // '放大缩小轮播',
    // '视频图片轮播',
    // '图片飞入',
    // '热区图片飞入',
    // '图片轮播',
    // '开门弹窗轮播',
    // '背景视频锚点轮播+ICON',
    // '无限滚动放大缩小',
    // '视频背景无限滚屏',
    // '视频轮播+ICON',
    // '锚点视频切换轮播飞入',
    // '视频背景左右滑动+ICON',
    // '图片轮播+箭头',
    // '图片轮播+ICON',
    // '背景视频轮播+箭头',
    // '背景视频锚点轮播图片飞入',
    // '渐变切换自定义热区',
    // '缩放弹窗轮播',
    // '翻转换一换',
    // '渐变换一换',
    // '锚点视频热区',
    // '大轮播嵌套小轮播'
]

export default [
    BannerPrice,
    SlideNavCompTabModule,
    SlideNavCompModule,
    CubeBannerAlert,
    AlertBannerModule,
    VideoNavBannerModule,
    ViedoBannerModule,
    InfinityScrollModule,
    SliderBannerModule,
    AccordionBannerModule,
    SpecialAccordion,
    MarqueeModule,
    ScrollModule,
    ExpandHideModule,
    NavBannerModule,
    CountDownModule,
    TestModule,
    VideoLeftAndRight,
    VideoScroll,
    MdNormalBannerFlyTopBottom,
    MdBannerFlyTop,
    MdBannerFlyTopBottom,
    BigBannerNestSmallOne,
    BgVideoNavBannerIcon,
    ImageBannerArrow,
    BgVideoBannerArrow,
    BgVideoBannerFlyImg,
    NormalFlyImage,
    MdBannerFlyBottom,
    CustomHotFlyImage,
    BgVideoMdBannerFlyImage,
    NavBannerFlyImageModule,
    ShadeChangeModule,
    ScaleBannerModule,
    VideoImageBanner,
    NormalImageBanner,
    OpenDoorBannerModule,
    ScaleLoopScrollModule,
    VideoBgLoopScroll,
    VideoBannerIcon,
    VideoBgLeftAndRightSlideDotIcon,
    ImageBannerIcon,
    TransparentHotBanner,
    CustomAlertBanner,
    FlipChangeModule,
    ShopInformModule,
    ScrollVideoModule,
    ScrollVideoIconModule,
    MdVideoHotBanner,
    BgVideoMdBannerArrow,
    MdVideoBannerFlyTopAndBottom,
    HotBgBanner,
    VideoBannerLoopScroll,
]